import { ConfigProvider } from 'antd'
// 引入英文语言包
import zhCN from 'antd/lib/locale/zh_CN'
import { BrowserRouter } from 'react-router-dom'
import { StoreProvider } from 'src/stores/index'
import moment from 'moment'
import 'moment/locale/zh-cn'
import App from './App'
import { homepage } from '../package.json'

moment.locale('zh-cn')

function Main() {
  return (
    <ConfigProvider locale={zhCN}>
      <StoreProvider>
        <BrowserRouter basename={homepage}>
          <App></App>
        </BrowserRouter>
      </StoreProvider>
    </ConfigProvider>
  )
}
export default Main
